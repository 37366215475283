// export const ROLECONSTANTS = {
//   superadmin: 'superadmin',
//   reselleradmin: 'reselleradmin',
//   billingadmin: 'billingadmin',
//   admin: 'admin',
//   user: 'user',
//   viewer: 'viewer',
// }

import { EXTERNAL_SYSTEM_INSTANCE_PROVIDER } from '../types/enums/external-system-instance-provider.enum';
import { ROUTER_TYPE } from '../types/enums/router-type.enum';

export enum COUNTRY_STATUS {
  AllEnabled = 'AllEnabled',
  NoneEnabled = 'NoneEnabled',
  SomeEnabled = 'SomeEnabled',
}

export const statusSMSSimOptions = [
  { id: 'Active', name: 'Active' },
  { id: 'Inactive', name: 'Inactive' },
  { id: 'Blocked', name: 'Blocked' },
  { id: 'Cancelled', name: 'Cancelled' },
];

export const countriesStatusOptions = [
  { id: 'AllEnabled', name: 'All Enabled' },
  { id: 'NoneEnabled', name: 'None Enabled' },
  { id: 'SomeEnabled', name: 'Some Enabled' },
];

export const ROLECONSTANTS_NAMES = {
  superadmin: 'Super Admin',
  admin: 'Admin',
  billingadmin: 'Billing Admin',
  user: 'User',
  viewer: 'Viewer',
  reselleradmin: 'Reseller Admin',
};

export const TICKET_TYPE = {
  SUPPORT: 'Support request',
  ACCOUNTING: 'Accounting request',
  PORT_REQUEST: 'Port request',
  ORDER: 'Order',
};

export enum ExternalSystemProvider {
  ALIANZA_PBX = 'ALIANZA_PBX',
  ALIANZA_TELCO = 'ALIANZA_TELCO',
  SKYSWITCH_PBX = 'SKYSWITCH_PBX',
  SKYSWITCH_TELCO = 'P00',
  NETSAPIENS_PBX = 'NETSAPIENS_PBX',
  RING_CENTRAL = 'RING_CENTRAL',
  BANDWIDTH_COM = 'BANDWIDTH_COM',
  TWILIO = 'TWILIO',
  QUESTBLUE = 'P03',
  SINCH = 'SinchFAX',
  MEGASWITCH_PBX = 'MegaSwitch-US',
  FAX_BACK_PBX = 'SecureFAX',
  SECURE_ALARM = 'P007',
  BYO_TELCO = 'BYO',
  BYO_PBX = 'BYO_PBX',
  ROUTE_TRUST_TELCO = 'P04',
}

export enum EXTERNAL_SYSTEM_PROVIDER_NAMES {
  P00 = 'P00',
  P03 = 'P03',
  P007 = 'P007',
  P04 = 'P04',
  ALIANZA_TELCO = 'ALIANZA_TELCO',
  SINCH = 'SinchFAX',
}

export const externalSystemProviderOptions = [
  { id: EXTERNAL_SYSTEM_PROVIDER_NAMES.P00, name: EXTERNAL_SYSTEM_PROVIDER_NAMES.P00 },
  { id: EXTERNAL_SYSTEM_PROVIDER_NAMES.P03, name: EXTERNAL_SYSTEM_PROVIDER_NAMES.P03 },
  { id: EXTERNAL_SYSTEM_PROVIDER_NAMES.P007, name: EXTERNAL_SYSTEM_PROVIDER_NAMES.P007 },
  { id: EXTERNAL_SYSTEM_PROVIDER_NAMES.P04, name: EXTERNAL_SYSTEM_PROVIDER_NAMES.P04 },
  { id: EXTERNAL_SYSTEM_PROVIDER_NAMES.ALIANZA_TELCO, name: 'Alianza' },
];

export enum EXTERNAL_SYSTEM_NAMES {
  SKYSWITCH = 'SS-23107',
  MEGASWITCH = 'MegaSwitch-US',
  FAX_BACK = 'SecureFAX',
}

export const externalSystemOptions = [
  { id: EXTERNAL_SYSTEM_NAMES.SKYSWITCH, name: EXTERNAL_SYSTEM_NAMES.SKYSWITCH },
  { id: EXTERNAL_SYSTEM_NAMES.MEGASWITCH, name: EXTERNAL_SYSTEM_NAMES.MEGASWITCH },
];

export const ORDER_TYPE = {
  ORDER: 'Order',
  RESELLER_ADMIN_ORDER: 'Reseller Admin Order',
};

export const EQUIPMENT_GROUP_PREVIEW_HASH_TYPE = {
  routers: 'router',
  batteries: 'battery',
  atas: 'atas',
  acs: 'acs',
  equipment_group: 'equipment-group',
  enclosures: 'enclosures',
  power_cycles: 'power-cycles',
  tc_sims: 'tc-sim',
  dialer: 'dialer',
};

export const CURRENT_STATUS = {
  BLACK: -2,
  ABNORMAL: -1,
  UNDEFINED: 0,
  OFFLINE: 1,
  ALERT: 2,
  ONLINE: 3,
};

export enum PORT_REQUEST_STATUS {
  UNKNOWN,
  CREATED,
  SENT,
  DIDS_CREATED,
  TEMP_DIDS_BOUGHT,
  PORTED,
  FOC_RECEIVED,
  CANCELED,
}

export const portRequestStatusNames = {
  [PORT_REQUEST_STATUS.UNKNOWN]: 'Unknown',
  [PORT_REQUEST_STATUS.CREATED]: 'Created',
  [PORT_REQUEST_STATUS.SENT]: 'Sent',
  [PORT_REQUEST_STATUS.DIDS_CREATED]: 'DIDs created',
  [PORT_REQUEST_STATUS.TEMP_DIDS_BOUGHT]: 'Temporary DIDs bought',
  [PORT_REQUEST_STATUS.PORTED]: 'Ported',
  [PORT_REQUEST_STATUS.FOC_RECEIVED]: 'FOC received',
  [PORT_REQUEST_STATUS.CANCELED]: 'canceled',
};

export const portRequestStatusOptions = Object.values(PORT_REQUEST_STATUS).map(value => ({
  name: portRequestStatusNames[value],
  id: value,
}));

export const SERVICE_CATEGORY = {
  TC_SERVICE_UNDEFINED: 'not_defined',
  TC_SERVICE_DID_LIC: 'did_lic',
  TC_SERVICE_DID_SEAT: 'did_seat',
  TC_SERVICE_DATA_PLAN: 'data_plan',
  TC_SERVICE_DIALER: 'dialer',
};

export const serviceCategoryOptions = Object.values(SERVICE_CATEGORY).map(value => ({
  name: value.replace('_', ' '),
  id: value,
}));

export const DIALER_STATUS = {
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  UNDEFINED: 'Undefined',
};

export const dialerOptions = Object.values(DIALER_STATUS).map(value => ({
  name: value,
  id: value,
}));

export const statusNames = {
  [CURRENT_STATUS.ABNORMAL]: 'Abnormal',
  [CURRENT_STATUS.UNDEFINED]: 'Undefined',
  [CURRENT_STATUS.OFFLINE]: 'Offline',
  [CURRENT_STATUS.ALERT]: 'Partially online',
  [CURRENT_STATUS.ONLINE]: 'Online',
  [CURRENT_STATUS.BLACK]: 'Disabled',
};

export const routerTypesOptions = Object.values(ROUTER_TYPE).map(value => ({
  name: value,
  id: value,
}));

export const pendingRouterTypesOptions = Object.values([
  ROUTER_TYPE.ATEL,
  ROUTER_TYPE.BECENTRAL,
  ROUTER_TYPE.CSG,
  ROUTER_TYPE.CRADLE_POINT,
  ROUTER_TYPE.IN_HAND_NETWORKS,
  ROUTER_TYPE.PEP_WAVE,
  ROUTER_TYPE.SIERRA_WIRELESS,
  ROUTER_TYPE.TELTONIKA_NETWORKS,
]).map(value => ({
  name: value,
  id: value,
}));

export const ISSUE_TRACKER_STATUS = {
  UNRESOLVED: 'Unresolved',
  RESOLVED: 'Resolved',
  IGNORED: 'Ignored',
  CANT_RESOLVE: "Can't Resolve",
};

export const issuesTrackerStatusOptions = Object.values(ISSUE_TRACKER_STATUS).map(value => ({
  name: value,
  id: value,
}));

export const ATA_TYPES = {
  GS: 'GS',
  ACS: 'ACS',
};

export const ataTypesOptions = Object.values(ATA_TYPES).map(value => ({
  name: value,
  id: value,
}));

export const externalSystemProviders = [
  {
    id: EXTERNAL_SYSTEM_INSTANCE_PROVIDER.SKYSWITCH_PBX,
    name: 'SkySwitch PBX',
  },
  {
    id: EXTERNAL_SYSTEM_INSTANCE_PROVIDER.NETSAPIENS_PBX,
    name: 'Netsapiens PBX',
  },
  {
    id: EXTERNAL_SYSTEM_INSTANCE_PROVIDER.ALIANZA_PBX,
    name: 'Alianza PBX',
  },
  {
    id: EXTERNAL_SYSTEM_INSTANCE_PROVIDER.SKYSWITCH_TELCO,
    name: 'SkySwitch TELCO',
  },
  {
    id: EXTERNAL_SYSTEM_INSTANCE_PROVIDER.QUESTBLUE,
    name: 'QuestBlue TELCO',
  },
  {
    id: EXTERNAL_SYSTEM_INSTANCE_PROVIDER.SINCH,
    name: 'Sinch TELCO',
  },
  {
    id: EXTERNAL_SYSTEM_INSTANCE_PROVIDER.ALIANZA_TELCO,
    name: 'Alianza TELCO',
  },
];

export const EQUIPMENT_TYPES = {
  ATA_ACS: 'Ata ACS',
  ATA_GS: 'Ata GS',
  BATTERY: 'Battery',
  DIALER: 'Dialer',
  ENCLOSURE: 'Enclosure',
  ROUTER: 'Router',
  SIM: 'SIM',
  POWER_CYCLE: 'PowerCycle',
};

export const equipmentTypesOptions = Object.values(EQUIPMENT_TYPES).map(value => ({ name: value, id: value }));

export const CREDENTIAL_TYPES = {
  Cradlepoint: 'cradlepoint',
  Pepwave: 'pepwave',
  InHand: 'inhandnetworks',
  SierraWireless: 'sierrawireless',
};

export const ADDRESS_STATUS = {
  UNDEFINED: 0,
  NOT_DELIVERABLE: 1,
  DELIVERABLE_WITH_ERROR: 2,
  DELIVERABLE: 3,
  SUPERADMIN_VALIDATED: 4,
};

// San Diego
export const DEFAULT_GEO_COORDINATES = {
  lng: -117.161087,
  lat: 32.715736,
};

export const activeStatusFilterArray = [
  { name: 'All', id: 'all' },
  { name: 'Offline', id: 'offline' },
  { name: 'Online', id: 'online' },
  { name: 'Inactive', id: 'inactive' },
];

export const inServiceFilterArray = [
  { name: 'All', id: 'all' },
  { name: 'In Service', id: 'true' },
  { name: 'Not Assigned', id: 'false' },
];

export const cdrsCallResultFilterArray = [
  { name: 'All', id: 'All' },
  { name: 'Cancelled', id: 'Cancelled' },
  { name: 'Answered', id: 'Answered' },
  { name: 'Busy', id: 'Busy' },
  { name: 'Other', id: 'Other' },
  { name: 'Unknown', id: 'Unknown' },
];

export const openStatusFilterArray = [
  { name: 'All', id: '1' },
  { name: 'Open', id: '2' },
  { name: 'Closed', id: '3' },
];

export const orgTypeFilterArray = [
  { name: 'All', id: '1' },
  { name: 'Provider', id: '2' },
  { name: 'BoBo', id: '3' },
];

export const currentStatusFilterArray = [
  { name: 'Abnormal', id: '-1' },
  { name: 'Unknown', id: '0' },
  { name: 'Offline', id: '1' },
  { name: 'Alert', id: '2' },
  { name: 'Online', id: '3' },
];

export const unassignedEquipmentStatus = {
  name: 'Unassigned',
  id: 'unassigned',
};

export const equipmentStatusFilterArray = [
  { name: 'All', id: 'all' },
  { name: 'Allocated', id: 'allocated' },
  { name: 'Not Allocated', id: 'not_allocated' },
  { name: 'In Inventory', id: 'in_inventory' },
];

export const ataPortsStatusFilterAray = [
  { name: 'Online', id: CURRENT_STATUS.ONLINE },
  { name: 'Offline', id: CURRENT_STATUS.OFFLINE },
  { name: 'Abnormal', id: CURRENT_STATUS.ABNORMAL },
  { name: 'Disabled', id: `${CURRENT_STATUS.BLACK}` },
  { name: 'Unknown', id: `${CURRENT_STATUS.UNDEFINED}` },
  { name: 'Empty', id: 'empty' },
];

export const booleanFilterArray = [
  { name: 'All', id: 'all' },
  { name: 'True', id: 'true' },
  { name: 'False', id: 'false' },
];

export const asyncTaskStatusFilterArray = [
  { name: 'In progress', id: 'In progress' },
  { name: 'Completed', id: 'Completed' },
  { name: 'Warnings', id: 'Warnings' },
  { name: 'Failed', id: 'Failed' },
  { name: 'Canceled', id: 'Canceled' },
];

export const ticketType = {
  SUPPORT_REQUEST: 'Support request',
  ACCOUNTING_REQUEST: 'Accounting request',
};

export const ORG_LEVEL = {
  TELCLOUD: 0,
  RESELLER: 1,
  SUB_ORG: 2,
  SUB_ORG_1: 3,
};

export const revioOverridesFields = [
  { label: 'ID', id: 'service_id' },
  { label: 'Provider ID', id: 'provider_id' },
  { label: 'Usage Plan Group ID', id: 'usage_plan_group_id' },
  { label: 'Package ID', id: 'package_id' },
];

export const orderStatus = {
  NEW: 'New',
  PROVISIONING_PORTAL: 'Provisioning Portal',
  PROVISIONING_HARDWARE: 'Provisioning Hardware',
  WAITING_ON_SIM_ACTIVATION: 'Waiting on SIM Activation',
  TESTING_LINES: 'Testing Lines',
  READY_TO_SHIP: 'Ready to Ship',
  SHIPPED: 'Shipped',
};
export const orderStatusFilterArray = Object.values(orderStatus).map(value => ({ name: value, id: value }));

export const shipCarrier = {
  UPS: 'UPS',
  FedEx: 'FedEx',
};
export const shipCarrierFilterArray = Object.values(shipCarrier).map(value => ({ name: value, id: value }));

export const shipMethods = {
  GROUND: 'Ground',
  OVERNIGHT: 'Overnight',
};
export const shipMethodsFilterArray = Object.values(shipMethods).map(value => ({ name: value, id: value }));

export const POWER_CYCLE_DEVICE_TYPE = {
  ATA: 'ATA',
  ACS_ATA: 'ACS ATA',
  ROUTER: 'Router',
  DIALER: 'Dialer',
  OTHER: 'Other',
};
export const powerCycleDeviceTypeFilterArray = Object.values(POWER_CYCLE_DEVICE_TYPE).map(value => ({
  name: value,
  id: value,
}));

export const ticketStatus = {
  NEW: 'New',
  OPEN: 'Open',
  // WAITING_FOR_RESPONSE: 'Waiting for response',
  CLOSED: 'Closed',
};

export const revioBoBoCollectionTemplates = [
  { id: 1, name: 'POTSOLVE COLLECTIONS' },
  { id: 2, name: 'ONESOURCE TELCO COLLECTIONS' },
  { id: 3, name: 'SOURCEINC COLLECTIONS' },
];

export const locationInfoPanels = {
  FIRE_PANEL: 'fire_panel',
  SECURITY_PANEL: 'security_panel',
  ELEVATOR_PANEL: 'elevator_panel',
};

export enum TICKET_PRIORITY {
  CRITICAL = 'P1',
  HIGH = 'P2',
  MEDIUM = 'P3',
  LOW = 'P4',
}

export enum TICKET_PRIORITY_DESCRIPTION {
  P1 = 'P1 (Critical - All Core Services Down)',
  P2 = 'P2 (High - Some Core Services Down)',
  P3 = 'P3 (Medium - Portal or Provisioning)',
  P4 = 'P4 (Low - Portal or Feature Request)',
}

export const ticketStatusFilterArray = Object.values(ticketStatus).map(value => ({ name: value, id: value }));
export const ticketPriorityFilterArray = Object.values(TICKET_PRIORITY).map(value => ({ name: value, id: value }));

export const stateCanadaCodes: Array<any> = [
  {
    name: 'CAN',
    options: [
      { name: 'Alberta', code: 'AB' },
      { name: 'British Columbia', code: 'BC' },
      { name: 'Manitoba', code: 'MB' },
      { name: 'New Brunswick', code: 'NB' },
      { name: 'Newfoundland and Labrador', code: 'NL' },
      { name: 'Nova Scotia', code: 'NS' },
      { name: 'Northwest Territories', code: 'NT' },
      { name: 'Nunavut', code: 'NU' },
      { name: 'Ontario', code: 'ON' },
      { name: 'Prince Edward Island', code: 'PE' },
      { name: 'Québec', code: 'QC' },
      { name: 'Saskatchewan', code: 'SK' },
      { name: 'Yukon', code: 'YT' },
    ],
  },
];

export const stateCodes: Array<any> = [
  {
    name: 'USA',
    options: [
      { name: 'Alabama', code: 'AL' },
      { name: 'Alaska', code: 'AK' },
      { name: 'American Samoa', code: 'AS' },
      { name: 'Arizona', code: 'AZ' },
      { name: 'Arkansas', code: 'AR' },
      { name: 'California', code: 'CA' },
      { name: 'Colorado', code: 'CO' },
      { name: 'Connecticut', code: 'CT' },
      { name: 'Delaware', code: 'DE' },
      { name: 'District Of Columbia', code: 'DC' },
      { name: 'Federated States Of Micronesia', code: 'FM' },
      { name: 'Florida', code: 'FL' },
      { name: 'Georgia', code: 'GA' },
      { name: 'Guam', code: 'GU' },
      { name: 'Hawaii', code: 'HI' },
      { name: 'Idaho', code: 'ID' },
      { name: 'Illinois', code: 'IL' },
      { name: 'Indiana', code: 'IN' },
      { name: 'Iowa', code: 'IA' },
      { name: 'Kansas', code: 'KS' },
      { name: 'Kentucky', code: 'KY' },
      { name: 'Louisiana', code: 'LA' },
      { name: 'Maine', code: 'ME' },
      { name: 'Marshall Islands', code: 'MH' },
      { name: 'Maryland', code: 'MD' },
      { name: 'Massachusetts', code: 'MA' },
      { name: 'Michigan', code: 'MI' },
      { name: 'Minnesota', code: 'MN' },
      { name: 'Mississippi', code: 'MS' },
      { name: 'Missouri', code: 'MO' },
      { name: 'Montana', code: 'MT' },
      { name: 'Nebraska', code: 'NE' },
      { name: 'Nevada', code: 'NV' },
      { name: 'New Hampshire', code: 'NH' },
      { name: 'New Jersey', code: 'NJ' },
      { name: 'New Mexico', code: 'NM' },
      { name: 'New York', code: 'NY' },
      { name: 'North Carolina', code: 'NC' },
      { name: 'North Dakota', code: 'ND' },
      { name: 'Northern Mariana Islands', code: 'MP' },
      { name: 'Ohio', code: 'OH' },
      { name: 'Oklahoma', code: 'OK' },
      { name: 'Oregon', code: 'OR' },
      { name: 'Palau', code: 'PW' },
      { name: 'Pennsylvania', code: 'PA' },
      { name: 'Puerto Rico', code: 'PR' },
      { name: 'Rhode Island', code: 'RI' },
      { name: 'South Carolina', code: 'SC' },
      { name: 'South Dakota', code: 'SD' },
      { name: 'Tennessee', code: 'TN' },
      { name: 'Texas', code: 'TX' },
      { name: 'Utah', code: 'UT' },
      { name: 'Vermont', code: 'VT' },
      { name: 'Virgin Islands', code: 'VI' },
      { name: 'Virginia', code: 'VA' },
      { name: 'Washington', code: 'WA' },
      { name: 'West Virginia', code: 'WV' },
      { name: 'Wisconsin', code: 'WI' },
      { name: 'Wyoming', code: 'WY' },
    ],
  },
  // {
  //   name: 'CAN',
  //   options: [
  //     { name: 'Alberta', code: 'AB' },
  //     { name: 'British Columbia', code: 'BC' },
  //     { name: 'Manitoba', code: 'MB' },
  //     { name: 'New Brunswick', code: 'NB' },
  //     { name: 'Newfoundland and Labrador', code: 'NL' },
  //     { name: 'Nova Scotia', code: 'NS' },
  //     { name: 'Northwest Territories', code: 'NT' },
  //     { name: 'Nunavut', code: 'NU' },
  //     { name: 'Ontario', code: 'ON' },
  //     { name: 'Prince Edward Island', code: 'PE' },
  //     { name: 'Québec', code: 'QC' },
  //     { name: 'Saskatchewan', code: 'SK' },
  //     { name: 'Yukon', code: 'YT' },
  //   ],
  // },
];

export const noAnswerTimeoutOptions = [
  { id: 0, name: 'Never' },
  { id: 5, name: '5' },
  { id: 10, name: '10' },
  { id: 15, name: '15' },
  { id: 20, name: '20' },
  { id: 25, name: '25' },
  { id: 30, name: '30' },
  { id: 35, name: '35' },
  { id: 40, name: '40' },
  { id: 45, name: '45' },
  { id: 50, name: '50' },
  { id: 55, name: '55' },
  { id: 60, name: '60' },
  { id: 90, name: '90' },
  { id: 120, name: '120' },
];

export const agreementType = {
  MASTER_SERVICES_AGREEMENT: 'mse',
  HIPPA: 'hippa',
};

export const DEFAULT_DID_VALUE = { did: '', country_code: '' };

export enum DATE_RANGE {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
  custom = 'custom',
}

export const dateRangeOptions = [
  { id: DATE_RANGE.day, name: 'Day' },
  { id: DATE_RANGE.month, name: 'Month' },
  { id: DATE_RANGE.year, name: 'Year' },
  { id: DATE_RANGE.custom, name: 'Custom Range' },
];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
