import { combineReducers } from 'redux';
import settings from './settings/Reducer';
import { locationsReducer } from './locations/locations.reducer';
import { sharedReducer } from './shared/shared.reducer';
import { loaderReducer } from './loader/loader.reducer';
import { servicesReducer } from './services/services.reducer';
import { utilsReducer } from './utils/utils.reducer';
import { serviceTypesReducer } from './serviceTypes/serviceTypes.reducer';
import { pbxsReducer } from './pbxs/pbxs.reducer';
import { statsReducer } from './stats/stats.reducer';
import { usersReducer } from './users/users.reducer';
import { paymentMethodsReducer } from './paymentMethods/paymentMethods.reducer';
import { atasReducer } from './atas/atas.reducer';
import { ataOrgTemplatesReducer } from './ataOrgTemplate/ataOrgTemplates.reducer';
import { ataBaseTemplatesReducer } from './ataBaseTemplates/ataBaseTemplates.reducer';
import { ataTypesReducer } from './ataTypes/ataTypes.reducer';
import { filesReducer } from './files/Reducer';
import { publicReducer } from './public/public.reducer';
import { ataLogsReducer } from './ataLogs/ataLogs.reducer';
import { ticketsReducer } from './tickets/ticket.reducer';
import { attachmentsReducer } from './attachments/attachments.reducer';
import { simsReducer } from './sims/sims.reducer';
import { dialerLogsReducer } from './dialerLogs/dialerLogs.reducer';
import { commentsReducer } from './comments/comments.reducer';
import { ordersReducer } from './orders/orders.reducer';
import { enclosureTypesReducer } from './enclosureTypes/enclosureTypes.reducer';
import { enclosuresReducer } from './enclosures/enclosures.reducer';
import { portingReducer } from './porting/porting.reducer';
import { inventoriesReducer } from './inventories/inventories.reducer';
import { dataPlanTypesReducer } from './dataPlanTypes/dataPlanTypes.reducer';
import { dataPlansReducer } from './dataPlans/dataPlans.reducer';
import { verificationReducer } from './verification/verification.reducer';
import { userAccessReducer } from './user-access/user-access.reducer';
import { countriesReducer } from './countries/countries.reducer';
import { smsLoggerReducer } from './smsLogger/smsLogger.reducer';
import { apiCredentialsReducer } from './apiCredentials/apiCredentials.reducer';
import notificationsReducer from '../features/notifications/notifications.slice';
import externalSystemsReducer from '../features/externalSystems/externalSystems.slice';
import cdrsReducer from '../features/cdrs/cdrs.slice';
import addressesReducer from '../features/addresses/addresses.slice';
import logsReducer from '../features/logs/logs.slice';
import secureAlarmReducer from '../features/secureAlarm/secureAlarm.slice';
import warehousesReducer from '../features/warehouses/warehouses.slice';
import batteriesReducer from '../features/batteries/batteries.slice';
import dialersReducer from '../features/dialers/dialers.slice';
import powerCyclesReducer from '../features/powerCycles/powerCycles.slice';
import pbxExtensionsReducer from '../features/pbxExtensions/pbxExtensions.slice';
import didsReducer from '../features/dids/dids.slice';
import contactIDMessagesReducer from '../features/contactIDMessages/contactIDMessages.slice';
import organizationsReducer from '../features/organizations/organizations.slice';
import didManagerReducer from '../features/didManager/didManager.slice';
import tcServiceTypesReducer from '../features/tcServiceTypes/tcServiceTypes.slice';
import routersReducer from '../features/routers/routers.slice';
import newSharedReducer from '../features/shared/shared.slice';
import authReducer from '../features/auth/auth.slice';
import equipmentGroupsReducer from '../features/equipmentGroups/equipmentGroups.slice';
import newATAsReducer from '../features/atas/atas.slice';
import { ticketNotesReducer } from './notes/ticketNotes.reducer';
import releaseNotesReducer from '../features/releaseNotes/releaseNotes.slice';
import contactsReducer from '../features/contacts/contacts.slice'
import salesReportsReducer from '../features/salesReports/salesReport.slice';

const reducers = combineReducers({
  contactsReducer,
  settings,
  locationsReducer,
  sharedReducer,
  loaderReducer,
  servicesReducer,
  serviceTypesReducer,
  utilsReducer,
  pbxsReducer,
  statsReducer,
  usersReducer,
  paymentMethodsReducer,
  atasReducer,
  ataOrgTemplatesReducer,
  ataBaseTemplatesReducer,
  ataTypesReducer,
  filesReducer,
  publicReducer,
  ataLogsReducer,
  ticketsReducer,
  attachmentsReducer,
  simsReducer,
  dialerLogsReducer,
  commentsReducer,
  ticketNotesReducer,
  ordersReducer,
  enclosureTypesReducer,
  enclosuresReducer,
  portingReducer,
  inventoriesReducer,
  dataPlanTypesReducer,
  dataPlansReducer,
  verificationReducer,
  userAccessReducer,
  countriesReducer,
  smsLoggerReducer,
  apiCredentialsReducer,
  equipmentGroupsReducer,
  externalSystemsReducer,
  organizationsReducer,
  didsReducer,
  notificationsReducer,
  cdrsReducer,
  addressesReducer,
  logsReducer,
  secureAlarmReducer,
  warehousesReducer,
  batteriesReducer,
  dialersReducer,
  powerCyclesReducer,
  pbxExtensionsReducer,
  contactIDMessagesReducer,
  didManagerReducer,
  tcServiceTypesReducer,
  routersReducer,
  newSharedReducer,
  authReducer,
  newATAsReducer,
  releaseNotesReducer,
  salesReportsReducer,
});

export default reducers;
