import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { ReleaseNoteListItemType, ReleaseNoteType } from '../../types/release-notes/release-notes';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { changeModalState, setModalContent } from '../../redux/utils/utils.action';

export const postReleaseNoteDetails = createAsyncThunk<ReleaseNoteType, { data: any }, { state: RootState }>(
  'releaseNotes/postReleaseNoteDetails',
  async ({ data }, thunkAPI) => {
    try {
      const isReleased = !data.scheduledReleaseDate;

      const payload = {
        title: data.title,
        versionNumber: data.versionNumber,
        releaseDate: data.releaseDate,
        scheduledReleaseDate: data.scheduledReleaseDate || null,
        updateType: data.updateType,
        description: data.description,
        targetDevices: data.targetDevices || [],
        newFeatures: data.newFeatures || [],
        bugFixes: data.bugFixes || [],
        microservices: data.microservices.map(ms => ({
          name: ms.name,
          ...(ms.id ? { id: ms.id } : {}),
        })),
        performanceImprovements: data.performanceImprovements || [],
        backupRequirement: data.backupRequirement,
        backupDetails: data.backupDetails || null,
        hardwareRequirements: data.hardwareRequirements || [],
        softwareRequirements: data.softwareRequirements || [],
        preparationInstructions: data.preparationInstructions || '',
        updateProcess: data.updateProcess || '',
        postUpdateInstructions: data.postUpdateInstructions || '',
        knownIssues: data.knownIssues || [],
        supportEmail: data.supportEmail || null,
        phoneNumber: data.phoneNumber || null,
        additionalNotes: data.additionalNotes || null,
        category: data.category || null,
        media: data.media || [],
        isReleased: data.isReleased || false,
        isPublic: data.isPublic || false,
        isInternal: data.isInternal || false,
        isAdminOnly: data.isAdminOnly || false,
        mainDeveloper: data.mainDeveloper || null,
        supportNumber: data.supportNumber || null,
        supportInformation: data.supportInformation || null,
      };

      const response = await axios.post<ReleaseNoteType>(`${process.env.REACT_APP_API_URL}/release-notes`, payload);

      if (isReleased) {
        await axios.post(`${process.env.REACT_APP_API_URL}/release-notifications/notify-all`, {
          releaseNoteId: response.data.id,
        });
      }

      const { user } = thunkAPI.getState().authReducer;
      if (user) {
        await thunkAPI.dispatch(getNotifications({ userId: user.id }));
      }

      thunkAPI.dispatch(getReleaseNotesList(thunkAPI.getState().releaseNotesReducer.releaseNotesRouteParams));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating release note!');
    }
  }
);

export const getReleaseNotesList = createAsyncThunk<
  PaginationType<ReleaseNoteListItemType>,
  Partial<RouteParamsType>,
  { state: RootState }
>('releaseNotes/getReleaseNotesList', async (params, thunkAPI) => {
  try {
    const queryParams = Object.keys(params)
      .filter(key => params[key] !== '' && params[key] !== undefined)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');

    const response = await axios.get<PaginationType<ReleaseNoteListItemType>>(
      `${process.env.REACT_APP_API_URL}/release-notes${queryParams ? `?${queryParams}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching release notes!');
  }
});

export const getReleaseNoteDetails = createAsyncThunk<ReleaseNoteType, { id: number }, { state: RootState }>(
  'releaseNotes/getReleaseNoteDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<ReleaseNoteType>(`${process.env.REACT_APP_API_URL}/release-notes/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching release note!');
    }
  }
);

export const patchReleaseNoteDetails = createAsyncThunk<
  ReleaseNoteType,
  { id: number; data: any },
  { state: RootState }
>('releaseNotes/patchReleaseNoteDetails', async ({ data, id }, thunkAPI) => {
  try {
    const isReleased = !data.scheduledReleaseDate;

    const payload = {
      title: data.title,
      versionNumber: data.versionNumber,
      releaseDate: data.releaseDate,
      scheduledReleaseDate: data.scheduledReleaseDate || null,
      updateType: data.updateType,
      description: data.description,
      targetDevices: data.targetDevices || [],
      newFeatures: data.newFeatures || [],
      bugFixes: data.bugFixes || [],
      microservices: data.microservices || [],
      performanceImprovements: data.performanceImprovements || [],
      backupRequirement: data.backupRequirement,
      backupDetails: data.backupDetails || null,
      hardwareRequirements: data.hardwareRequirements || [],
      softwareRequirements: data.softwareRequirements || [],
      preparationInstructions: data.preparationInstructions || '',
      updateProcess: data.updateProcess || '',
      postUpdateInstructions: data.postUpdateInstructions || '',
      knownIssues: data.knownIssues || [],
      supportEmail: data.supportEmail || null,
      phoneNumber: data.phoneNumber || null,
      additionalNotes: data.additionalNotes || null,
      category: data.category || null,
      media: data.media || [],
      isReleased,
      isPublic: data.isPublic || false,
      isInternal: data.isInternal || false,
      isAdminOnly: data.isAdminOnly || false,
      mainDeveloper: data.mainDeveloper || null,
      supportNumber: data.supportNumber || null,
      supportInformation: data.supportInformation || null,
    };

    const response = await axios.patch<ReleaseNoteType>(
      `${process.env.REACT_APP_API_URL}/release-notes/${id}`,
      payload
    );

    if (isReleased) {
      await axios.post(`${process.env.REACT_APP_API_URL}/release-notifications/notify-all`, {
        releaseNoteId: response.data.id,
      });
    }

    thunkAPI.dispatch(getReleaseNotesList(thunkAPI.getState().releaseNotesReducer.releaseNotesRouteParams));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating release note!');
  }
});

export const deleteReleaseNoteDetails = createAsyncThunk<
  ReleaseNoteType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('releaseNotes/deleteReleaseNoteDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<ReleaseNoteType>(`${process.env.REACT_APP_API_URL}/release-notes/${id}`);
    if (fetchList)
      thunkAPI.dispatch(getReleaseNotesList(thunkAPI.getState().releaseNotesReducer.releaseNotesRouteParams));
    thunkAPI.dispatch(changeModalState(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting release note!');
  }
});

export const getReleaseNoteMicroservicesList = createAsyncThunk<
  Array<{ id: number; name: string }>,
  void,
  { state: RootState }
>('releaseNotes/getReleaseNoteMicroservicesList', async (_, thunkAPI) => {
  try {
    const response = await axios.get<Array<{ id: number; name: string }>>(
      `${process.env.REACT_APP_API_URL}/release-notes/microservices`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching release note microservices!');
  }
});

export const markAllNotificationsAsRead = createAsyncThunk<
  Array<{ id: number; name: string }>,
  { userId: string },
  { state: RootState }
>('releaseNotes/markAllNotificationsAsRead', async ({ userId }, thunkAPI) => {
  try {
    const response = await axios.patch<Array<{ id: number; name: string }>>(
      `${process.env.REACT_APP_API_URL}/release-notifications/mark-all-as-read`,
      undefined
    );
    thunkAPI.dispatch(getNotifications({ userId }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during marking all notifications as read!');
  }
});

export const markNotificationAsRead = createAsyncThunk<
  Array<{ id: number; name: string }>,
  { userId: string; notificationId: string },
  { state: RootState }
>('releaseNotes/markNotificationAsRead', async ({ userId, notificationId }, thunkAPI) => {
  try {
    const response = await axios.patch<Array<{ id: number; name: string }>>(
      `${process.env.REACT_APP_API_URL}/release-notifications/${notificationId}/mark-as-read`,
      undefined
    );
    thunkAPI.dispatch(getNotifications({ userId }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during marking notification as read!');
  }
});

export const getNotifications = createAsyncThunk<
  { notifications: Array<{ releaseNote: ReleaseNoteType }>; unreadCount: number },
  { userId: string },
  { state: RootState }
>('releaseNotes/getNotifications', async ({ userId }, thunkAPI) => {
  try {
    const response = await axios.get<{
      notifications: Array<{ releaseNote: ReleaseNoteType }>;
      unreadCount: number;
    }>(`${process.env.REACT_APP_API_URL}/release-notifications`, {
      params: { userId },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching notifications!');
  }
});
